import './../../scss/blocks/_modal.scss'
import {
    handleModalOpen,
    handleModalCloseButton,
    handleModalCloseEscapeButton,
    handleModalCloseBackground,
    handleFileInputChange,
    handleSelectDropdowns,
    handleFormSubmit,
} from '../components/modalInit'

document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener('click', handleModalOpen)
    document.querySelectorAll('.js-modal').forEach((modal) => {
        modal.addEventListener('click', handleModalCloseBackground)
    })
    document.querySelectorAll('.olap-modal-close').forEach((button) => {
        button.addEventListener('click', handleModalCloseButton)
    })
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
            handleModalCloseEscapeButton()
        }
    })
    document.querySelectorAll('.olap-form__group--file input[type=file]').forEach((input) => {
        input.addEventListener('change', handleFileInputChange)
    })
    handleSelectDropdowns()

    document.querySelectorAll('.olap-form').forEach((form) => {
        form.addEventListener('submit', handleFormSubmit)
    })
})
